import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AddIcon from '@material-ui/icons/Add';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Container from '~components/Container';
import RichText from '~components/RichText';
import useHeaderStyle from '~styles/useHeaderStyle';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import RegisterButton from '~containers/landing/RegisterButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '70px',
    paddingBottom: '126px',
    gap: 64,
    [theme.breakpoints.down('xs')]: {
      gap: 30,
      paddingTop: '40px',
      paddingBottom: '60px'
    }
  },
  accordion: {
    color: props => props.item_text_color,
    backgroundColor: props => props.item_background_color,
    borderRadius: '10px !important',
    padding: '8px 15px 8px 15px',
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.10)',
    '&:before': {
      backgroundColor: 'unset !important'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px 0px 5px'
    }
  },
  accordionExpanIcon: {
    color: props => props.item_text_color,
    fontSize: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  description: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
    '& a': {
      color: props => props.link_color || props.item_text_color,
      fontWeight: 500
    }
  },
  section: {
    position: 'relative',
    backgroundColor: props => props.background_color
  }
}));

const Faq = ({
  title,
  items,
  background_color,
  text_color,
  item_background_color,
  item_text_color,
  link_color,
  cta_theme,
  bottom_shape_divider
}) => {
  const classes = useStyles({
    background_color,
    item_background_color,
    item_text_color,
    link_color
  });
  const headerStyle = useHeaderStyle({ color: text_color });

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Typography variant="h3" className={headerStyle.h2}>
          {title}
        </Typography>
        <Container maxWidth="md">
          <Box
            component="div"
            width="100%"
            display="flex"
            flexDirection="column"
            gridGap="9px"
            marginBottom="40px"
          >
            {items.map((item, index) => (
              <Accordion key={item.title} className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<AddIcon className={classes.accordionExpanIcon} />}
                  aria-controls={`panel-${index}-content`}
                  id={`panel-${index}-header`}
                >
                  <Typography className={classes.title}>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RichText html={item.description} externalClassName={classes.description} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            <RegisterButton
              size="small"
              variant={cta_theme}
              analyticsTitle={title}
              analyticsSection="FAQ"
            />
          </Box>
        </Container>
      </div>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

Faq.propTypes = {
  title: PropTypes.string,
  background_color: PropTypes.string,
  text_color: PropTypes.string,
  item_background_color: PropTypes.string,
  item_text_color: PropTypes.string,
  link_color: PropTypes.string,
  cta_theme: PropTypes.string,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  )
};

Faq.defaultProps = {
  title: 'Frequently Asked Questions',
  background_color: '#397bb9',
  text_color: '#ffffff',
  item_background_color: '#5fa2e0',
  item_text_color: '#ffffff',
  link_color: '#8ae1ff',
  cta_theme: 'white',
  bottom_shape_divider: null,
  items: []
};

export default Faq;
